<template>
  <div
    class="row mt-5 w-85 big-div"
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <div
      class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0 h-100"
      v-show="showLeftImage"
      :class="{
        'col-12 col-lg-12 col-xs-12': !isDesktopView,
        'image-division': isDesktopView,
      }"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage />
     :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style
              ) + getImageOpacity(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-SharePage`,
              pageDesign.imageBlock.src == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(pageDesign.imageBlock.src)"
            alt
         
        </div>
      </div>
    </div>

    <div
      :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
      class=" "
      :style="
        getMainPaddingCss(pageDesign.pageStyles) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style
        )
      "
    >
      <div>
        <div
          v-for="(pro, ind) in pageDesign.blocksArray"
          :key="'preview' + ind"
          class=""
        >
          <div
            id="preview"
            :style="getHeightWidth(pro.style)"
            class="content"
            style="position: relative"
          >
            <div
              v-if="pro.type == 'text'"
              :style="
                getColorCss(pro.style) +
                getPaddingCss(pro.style) +
                getMarginCss(pro.style) +
                getColorWithOpacity(pro.style) +
                getTextRotate(pro.style) +
                getLineHeight(pro.style) +
                getTextAlign(pro.style) +
                getFontStyle(pro.style,isDesktopView)
              "
              v-html="pro.content"
            ></div>

            <div
              v-else
              :style="
                'display:flex;justify-content:' + pro.style.position + ';'
              "
            >
              <button :style="getButtonCss(pro.style,isDesktopView)">
                <span
                  :style="{
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  }"
                >
                  {{ pro.text }}
                </span>
                {{ pro.style.alignItems }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0 h-100"
      v-show="showRightImage"
      :class="{
        'col-12 col-lg-12 col-xs-12': !isDesktopView,
        'image-division': isDesktopView,
      }"
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style
              ) +
              getImageOpacity(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-SharePage`,
              pageDesign.imageBlock.src == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(pageDesign.imageBlock.src)"
            alt
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cssFunctionMixin from "../../customize/mixin/cssFunction.js";
export default {
  mixins: [cssFunctionMixin],
  props: ["isDesktopView", "pageDesign"],
  computed: {
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },

    IsImageAdded() {
      if (this.pageDesign.imageBlock.src !== "") return true;
      return false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-md-6 col-sm-12  col-12 col-xs-12";
    },
  },
  methods: {
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.pageDesign.pageStyles.height +
        "px;" +
        (this.isDesktopView
          ? "width:" + this.pageDesign.pageStyles.width + ";"
          : "width:350px;");

      return design;
    },
  },
};
</script>

<style></style>
